import React from 'react';
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imageCompany from "../../assets/course-search_banner.png";

const CourseSearch = () => {
  return (
    <div >
    <div className="inner-banner-section">
    <div className="inner-banner-mask"></div>
    <img src={imageCompany} alt="Company" className="img-fluid" />
    <div className="inner-banner-caption">
      <h2 className="display-5">Course search</h2>
      <p>Your Gateway to 1,000+ Institutions Worldwide
      </p>
    </div>
  </div>
  <div className="osc-section pt-5 pb-5">
  <div className="container-fluid">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          
          <p className="text-center">
          Empower your academic journey with access to leading institutions across the globe. Whether you're seeking universities, high schools, or pathway programs, Course Search makes it effortless to find the right fit for every student need.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div className='common-section pt-5'>
    <div className="container-fluid">
        <div className="container">
        <h4 className="display-6">Global Reach, Growing Daily</h4>
        <p>Our platform is constantly updated with new partnerships, so you’ll never miss an opportunity.</p>
        <h4 className="display-6">Discover Scholarships with Ease</h4>
        <p>Easily view available scholarships, including exclusive opportunities, helping students achieve their dreams while staying within budget.</p>
        <h4 className="display-6">Advanced One-Click Search</h4>
        <p>Search from over 100,000 courses with just a click! Use advanced filtering tools to refine results by:</p>
            <ul className="arrow-v-list-1">
                <li>Country</li>
                <li>Tuition Fees</li>
                <li>Duration</li>
                <li>Program Type</li>
                <li>Scholarships Availability</li>
                
            </ul>
            <h4 className="display-6 pt-3">Personalized Matchmaking</h4>
            <p>Find the perfect course for every student. Our intelligent algorithm delivers tailored recommendations, ensuring students get access to options that match their eligibility and aspirations.</p>
            <h4 className="display-6 ">Built for Every Need</h4>
            <p>Whether your students are pursuing:</p>
            <ul className="arrow-v-list-1">
                <li>High School Programs</li>
                <li>Undergraduate Degrees</li>
                <li>Pathway Programs</li>
                <li>Postgraduate Studies</li>
                <li>Research Courses</li>
                <li>PHD</li>
            </ul>

        </div>
    </div>
</div>
</div>
  );
};

export default CourseSearch