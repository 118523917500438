import React, { useState, useEffect } from "react";
import imageCompany from "../../assets/contact_us.jpg";
import axios from 'axios';
import 'remixicon/fonts/remixicon.css';
import { toast, ToastContainer } from 'react-toastify';



const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
    userType: ""
  });

  const [ipAddress, setIpAddress] = useState("");
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIpAddress("Unable to fetch IP"); // Fallback in case of error
      }
    };

    fetchIpAddress();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      id: 0,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      country: formData.country,
      message: formData.message,
      type: formData.userType,
      ipAddress: ipAddress, // Use the dynamically fetched IP address
      macAddress: 'Null',
      website: "FlyUrDream.com",
      createdOn: new Date().toISOString(),
      isActive: true,
    };
    console.log('before', payload)
    try {
      const response = await axios.post('https://fudreamapi.azurewebsites.net/AddFlyurdreamEnquiry', payload);

      toast.success(response.data.message)
      setFormData({
        name: "",
        email: "",
        phone: "",
        country: "",
        message: "",
      });
      console.log('after', response.data)
    } catch (error) {
      toast.error("Error submitting enquiry:", error);
    }
  };
  return (
    <div>
      <div className="inner-banner-section">
        <div className="inner-banner-mask"></div>
        <img src={imageCompany} alt="Company" className="img-fluid" />
        <div className="inner-banner-caption">
          <h2 className="display-5">Contact Us - Fly Ur Dream</h2>
          <p>Have Questions?<br />
            Don't Hesitate to Contact Us
          </p>
        </div>
      </div>
      <div className="osc-section pt-5 pb-5">
        <div className="container-fluid">
        <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h2 className="display-5">Connecting
                    <span className="text-c-2">Worldwide</span></h2>
                </div>
                <p className="text-center">
                  At FlyUrDream, we are dedicated to transforming students’ aspirations into reality by providing comprehensive support throughout their international education journey. Our mission is to simplify the complexities of studying abroad, ensuring that every student has access to quality education opportunities worldwide.
                </p>
              </div>
            </div>
            <div class="row pt-3">
           
            <div class="col-md-6 col-sm-12 ">
              <div class="shadow-sm  p-3 bg-light">
                <h4>UNITED KINGDOM (Head Quarters)
                </h4>
                <address> Vista Business Centre, 50 Salisbury Road, Hounslow, <br/>
                   England, TW4 6JQ.</address>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 ">
                <div class="shadow-sm  p-3   bg-light">
                  <h4>INDIA</h4>
                  <address>FLAT NO 601, HUDA Techno Enclave, HITEC City, Hyderabad, Telangana 500081, India.</address>
                </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6 col-sm-12">
              <div class="shadow-sm  p-3   bg-light">
                <h4>UNITED ARAB EMIRATES</h4>
                <address>Shams Business Center, Sharjah Media City, P.O.Box 515000, Sharjah, United Arab Emirates.</address>
              </div>
            </div>
            {/* <div class="col-md-6 col-sm-12 ">
              <div class="shadow-sm  p-3  bg-light">
                <h4>Vijayawada</h4>
                <address>United kingdom
                  Vista Business Centre,
                  50 Salisbury Road,
                  Hounslow, England,
                  TW4 6JQ.</address>
              </div>
            </div> */}
          </div>

          </div>
        </div>
      </div>

      <div className="bg-wl-br pt-5 c-form-1">
      <div className="d-flex align-items-center bd-highlight mb-3 container" >
      <div className="p-2 bd-highlight col-md-6 col-sm-12">
      Let’s Connect
                <h4 className="display-6">Send Your Message
                </h4>
                <p>Please feel free to get in touch with us using the contact form below.

                </p>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <label className="form-label">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Country</label>
                      <select
                        className="form-select"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Choose Country
                        </option>
                        <option value="USA">USA</option>
                        <option value="CANADA">Canada</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="radio-contact">
                      <label className="form-label">User Type</label>
                      <div>
                        <input
                          type="radio"
                          name="userType"
                          value="Student"
                          checked={formData.userType === "Student"}
                          onChange={handleChange}
                        />
                        <label className="ms-2">Student</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="userType"
                          value="Recruiter"
                          checked={formData.userType === "Recruiter"}
                          onChange={handleChange}
                        />
                        <label className="ms-2">Recruiter</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="userType"
                          value="Other"
                          checked={formData.userType === "Other"}
                          onChange={handleChange}
                        />
                        <label className="ms-2">Other</label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Message</label>
                      <textarea
                        className="form-control"
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        style={{ height: "100px" }}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
      </div>
      <div className="p-2 bd-highlight col-md-6 col-sm-12 text-c-3 c-form-1-content ">
      <h4 className="display-6">Opening Hour
      </h4>
            <ul>
              <li>
              <b>Monday: </b> 10.00 am to 06.00 pm
              </li>
              <li>
              <b>Tuesday: </b> 10.00 am to 06.00 pm
              </li>
              <li>
              <b>Wednesday: </b> 10.00 am to 06.00 pm
              </li>
              <li>
              <b>Thrusday: </b> 10.00 am to 06.00 pm
              </li>
              <li>
              <b>Friday: </b> 10.00 am to 06.00 pm
              </li>
              <li>
              <b>Saturday: </b> 10.00 am to 06.00 pm
              </li>
              <li>
              <b>Sunday: </b> 10.00 am to 06.00 pm
              </li>
             
            </ul>
      </div>
      </div>
      </div>

      {/* <div className="common-section pt-5">
        <div className="container-fluid">
          <div className="container">

            <div className="row">
              <div className="col-md-6 col-sm-12">


                <h4 className="display-6">Opening Hour
                </h4>
                <address>
                  <b>  Monday:</b> <span className='time-common'>10.00 am to 06.00 pm</span>
                </address>
                <address>
                  <b>  Tuesday:</b> <span className='time-common'>10.00 am to 06.00 pm</span>
                </address>
                <address>
                  <b>  Wednesday:</b>  <span className='time'>10.00 am to 06.00 pm</span>
                </address>
                <address>
                  <b>  Thrusday:</b>  <span className='time-common2'>10.00 am to 06.00 pm</span>
                </address>
                <address>
                  <b>  Friday:</b>  <span className='time-common3'>10.00 am to 06.00 pm</span>
                </address>
                <address>
                  <b>  Saturday:</b>  <span className='time-common2'>10.00 am to 06.00 pm</span>
                </address>
                <address>
                  <b>  Sunday:</b>  <span className='time-common1'>10.00 am to 06.00 pm</span>
                </address>

              </div>
              <div className="col-md-6 col-sm-12">
                Let’s Connect
                <h4 className="display-6">Send Your Message
                </h4>
                <p>Please feel free to get in touch with us using the contact form below.

                </p>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <label className="form-label">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Country</label>
                      <select
                        className="form-select"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Choose Country
                        </option>
                        <option value="USA">USA</option>
                        <option value="CANADA">Canada</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="radio-contact">
                      <label className="form-label">User Type</label>
                      <div>
                        <input
                          type="radio"
                          name="userType"
                          value="Student"
                          checked={formData.userType === "Student"}
                          onChange={handleChange}
                        />
                        <label className="ms-2">Student</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="userType"
                          value="Recruiter"
                          checked={formData.userType === "Recruiter"}
                          onChange={handleChange}
                        />
                        <label className="ms-2">Recruiter</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="userType"
                          value="Other"
                          checked={formData.userType === "Other"}
                          onChange={handleChange}
                        />
                        <label className="ms-2">Other</label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Message</label>
                      <textarea
                        className="form-control"
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        style={{ height: "100px" }}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
        <ToastContainer />
      </div> */}
    </div>
  );
};

export default Contact;
