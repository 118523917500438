import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imageCompany from "../../assets/company.png";
import 'remixicon/fonts/remixicon.css';

const CookiePolicy = () => {
  return (
    <div >
      <div className="inner-banner-section">
      <div className="inner-banner-mask"></div>
    <img src={imageCompany} alt="Company" className="img-fluid"/>
    <div className="inner-banner-caption">
      <h2 className="display-5">FLYURDREAM Cookie Policy</h2>
      <p></p>
    </div>
  </div>
    <div className="osc-section pt-5 pb-5">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 className="display-5"> FLYURDREAM <span className="text-c-2">Cookie Policy</span></h2>
              </div>
              <p className="text-center">
                This Cookie Policy helps to explain the occasions when and conditions under which cookies may be sent to visitors of our websites. By using or accessing FLYURDREAM’s websites, you are consenting to FLYURDREAM’s use of Cookies as outlined below. For the purposes of this Cookie Policy, “FLYURDREAM” or “we” means FLYURDREAM LTD.
              </p>
            </div>
          </div>
         
        </div>
      </div>
    </div>
    <div className="common-section pt-5">
      <div className="container-fluid">
        <div className="container">
      
          <div className="row">
           <div className="col-md-12">
            <h4 className="display-6">Cookie Use</h4>
            <ul className="arrow-v-list-1">
              <li>Cookies are small text files that can be used by websites to make a user’s experience more efficient.</li>
              <li>The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission.</li>
              <li>This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.</li>
              <li>You can at any time change or withdraw your consent from the Cookie Declaration on our website.</li>
              <li>Learn more about who we are, how you can contact us and how we process personal data in our Privacy Policy.</li>
              <li>Please state your consent ID and date when you contact us regarding your consent.</li>
            
            </ul>
            <h4 className="display-6">What is a cookie?</h4>
            <p>“Cookies” are text-only pieces of information that a web server transfers to an individual’s hard drive or other website-browsing equipment for record keeping purposes. Cookies allow a website to remember important information that makes use of a website more convenient. A cookie will typically contain the name of the domain from which the cookie has come, the “lifetime” of the cookie, and a randomly generated unique number or other value. Session cookies are temporary cookies that remain in the cookie file of your browser until you leave the website.</p>
            <p>Persistent cookies remain in the cookie file of your browser for much longer (though how long will depend on the lifetime of the specific cookie). When we use session cookies to track the total number of visitors to our Site, this is done on an anonymous aggregate basis (as cookies do not in themselves carry any personal data). We may also employ cookies so that we remember your computer when it is used to return to the Site to help customise your experience with us. We may associate personal information with a cookie file in those instances.</p>
            <h3 className="display-5">How FLYURDREAM uses cookies</h3>
            <h4 className="display-6">Analytical Purposes</h4>
            <p>We use cookies to analyse user activity in order to improve our websites. For example, we can use cookies to look at aggregate patterns like the average number of course searches that users perform. We can use such analysis to gain insights about how to improve the functionality and user experience of the Site.</p>
            <h4 className="display-6">Your Preferences and User Experience</h4>
            <p>We use cookies to gather certain information about users, such as browser type, operating system, language preference, and country setting, in order to store user preferences on our websites to make your user experience more consistent and convenient. For example, we may store the recent searches you have performed in a cookie so that we can allow you to easily repeat those searches when you return to our website. We may also use your IP address to determine your geographic location in order to provide you with the most relevant search results.

            </p>
            <h4 className="display-6">Marketing</h4>
            <p>We use cookies from third-party partners such as Google and Facebook for marketing purposes. These cookies allow us to display <a href="https://flyurdream.com/">FLYURDREAM</a> promotional material to you on other sites you visit across the Internet. <a href="https://flyurdream.com/">FLYURDREAM</a> may also share information about your behaviour on the Site with third parties in order to show you targeted advertisements and other content that has been customized for you.</p>
            <h4 className="display-6">Referral Tracking</h4>
            <p>We use cookies to associate user activity with the third party website that referred the user to our website. We also use cookies to associate user activity with the email campaign that referred the user to our Site.</p>
            <h4 className="display-6">Managing cookies in your browser</h4>
            <p>Use the options in your web browser if you do not wish to receive a cookie or if you wish to set your browser to notify you when you receive a cookie. You can easily delete and manage any cookies that have been installed in the cookie folder of your browser by following the instructions provided by your particular browser manufacturer.</p>
            <h4 className="display-6">Cookie Policy Updates</h4>
            <p>It may be necessary for us to change this cookie policy. We suggest that you check here from time to time for any changes.</p>
            <h4 className="display-6">Our details</h4>
            <p>This website is owned and operated by <a href="https://flyurdream.com/">Flyurdream </a>LTD</p>
        
            <p>We Registered in England, Company Registration Number 12243724 and our Registered Office:Vista Business Centre, 50 Salisbury Road, Hounslow, England, TW4 6JQ</p>
           </div>
            
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CookiePolicy;
