import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imagestudent1 from "../../assets/students_banner.png";
import imagesStudent2 from "../../assets/Institutions_banner.png";
import imagesStudent3 from "../../assets/students_banner_one-01.png";
import axios from 'axios';
import 'remixicon/fonts/remixicon.css';
import { toast, ToastContainer } from 'react-toastify';

const StudentPage = () => {
  const fixedRef = useRef(null);
  const scrollEndRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });
  const [ipAddress, setIpAddress] = useState("");
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIpAddress("Unable to fetch IP"); // Fallback in case of error
      }
    };

    fetchIpAddress();
    const handleScroll = () => {
      // debugger;
      if (fixedRef.current && scrollEndRef.current) {
        const elementPosition = fixedRef.current.getBoundingClientRect();
        const scrollEnd = scrollEndRef.current.getBoundingClientRect();
        const scrollEndPosition = scrollEndRef.current.getBoundingClientRect();
        const mediaQuery991 = window.matchMedia('(min-width: 991px)');

        if (window.scrollY > elementPosition.top) {
          if (mediaQuery991.matches) {
            fixedRef.current.style.position = 'fixed';
            fixedRef.current.style.top = '0';
            if (scrollEnd.top <= 100) {
              fixedRef.current.style.position = 'static';
            }
          } else {
            fixedRef.current.style.position = 'static';
          }
        } else {
          fixedRef.current.style.position = 'static';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      id: 0,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      country: formData.country,
      message: formData.message,
      type: "Student",
      ipAddress: ipAddress,
      macAddress: "string",
      website: "FlyUrDream.com",
      createdOn: new Date().toISOString(),
      isActive: true,
    };
    console.log('before', payload)
    try {
      const response = await axios.post('https://fudreamapi.azurewebsites.net/AddFlyurdreamEnquiry', payload);

      toast.success(response.data.message)
      setFormData({
        name: "",
        email: "",
        phone: "",
        country: "",
        message: "",
      });
      console.log('after', response.data)
    } catch (error) {
      toast.error("Error submitting enquiry:", error);
    }
  };
  return (
    <div >
      <div className="institutions-banner-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="video-frame">
                <img src={imagesStudent3} className="d-block w-100" alt="..." />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="video-content text-c-1">
                <h4 className="display-6">Students</h4>
                <p>Dreaming of studying abroad? At FlyUrDream, we make your international education journey smooth, hassle-free, and inspiring. Our expert team is here to guide you every step of the way, ensuring you’re equipped to embark on this life-changing adventure. Let’s break it down into simple, achievable steps</p>
                <div className="row">
                  <div className="col text-center count-box">
                    <h4>1000+</h4>
                    <p>Universities  </p>
                  </div>
                  <div className="col text-center count-box">
                    <h4>70K</h4>
                    <p>Courses </p>
                  </div>
                  <div className="col text-center count-box">
                    <h4>10k</h4>
                    <p>Success Students </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="in-banner-layer">

        </div>

      </div>
      <div className="osc-section pt-5 pb-5">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div id="fixed" ref={fixedRef}>
                  <ul className="left-nav-list">
                    <li>
                      <a href="#PersonalizedCounseling">Personalized Counseling</a>
                    </li>
                    <li>
                      <a href="#TestPreparation">Test Preparation</a>
                    </li>
                    <li>
                      <a href="#CourseCountryandUniversitySelection">Course, Country, and University Selection</a>
                    </li>
                    <li>
                      <a href="#ApplicationandAdmissionAssistance">Application and Admission Assistance</a>
                    </li>
                    <li>
                      <a href="#ScholarshipGuidance">Scholarship Guidance</a>
                    </li>
                    <li>
                      <a href="#InternshipOpportunities">Internship Opportunities</a>
                    </li>
                    <li>
                      <a href="#EducationLoanAssistance">Education Loan Assistance</a>
                    </li>
                    <li>
                      <a href="#VisaProcessingSupport">Visa Processing Support</a>
                    </li>
                    <li>
                      <a href="#AlliedServices"  >Allied Services</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-8 col-sm-12">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="display-6" id="PersonalizedCounseling">Personalized Counseling</h4>
                    <p>Understanding your unique academic goals and career aspirations is our priority. Our experienced counselors provide tailored advice to help you make informed decisions about your study abroad journey.</p>
                    <ul className="arrow-v-list-1">
                      <li><b>Career-Oriented Guidance:</b> We focus on aligning your academic choices with your long-term career objectives.</li>
                      <li><b>Interactive Sessions:</b> Engage in discussions with university representatives to gain insights into various programs and institutions.</li>
                    </ul>
                    <h4 className="display-6" id="TestPreparation">Test Preparation</h4>
                    <p>Achieving competitive scores on standardized tests is crucial for admission to international universities. Our certified tutors offer comprehensive preparation for exams such as IELTS, TOEFL, GRE, and GMAT.</p>
                    <ul className="arrow-v-list-1">
                      <li><b>Interactive Learning:</b> Participate in engaging classroom sessions and access free demo classes.</li>
                      <li><b>Effective Study Materials:</b> Utilize resources designed to simplify complex concepts and enhance your understanding.</li>
                      <li><b>Mock Tests:</b> Regular practice exams to assess your progress and readiness.</li>
                    </ul>
                    <h4 className="display-6" id="CourseCountryandUniversitySelection">Course, Country, and University Selection</h4>
                    <p>Choosing the right program and institution is vital for your academic success. We assist you in selecting options that align with your interests, academic background, and budget.</p>
                    <ul className="arrow-v-list-1">
                      <li><b>Informed Decision-Making:</b> Receive guidance on making precise academic and career choices.</li>
                      <li><b>University Comparison:</b> Evaluate institutions based on rankings, courses offered, and available scholarships.</li>
                      <li><b>Extensive Options:</b> Explore a wide range of courses across numerous universities in various countries.</li>
                    </ul>
                    <h4 className="display-6" id="ApplicationandAdmissionAssistance">Application and Admission Assistance</h4>
                    <p>Navigating the application process can be complex. Our team ensures that your applications are meticulously prepared and submitted promptly.</p>
                    <ul className="arrow-v-list-1">
                      <li><b>Flawless Applications:</b> Increase your chances of acceptance with well-prepared applications.</li>
                      <li><b>High-Quality Documentation:</b> Assistance in crafting compelling Statements of Purpose (SOPs), Letters of Recommendation (LORs), and resumes.</li>
                      <li><b>Real-Time Tracking:</b> Monitor your application status and receive timely updates.</li>

                    </ul>
                    <h4 className="display-6" id="ScholarshipGuidance">Scholarship Guidance</h4>
                    <p>Financing your education is a critical aspect of studying abroad. We help you identify and apply for scholarships that you are eligible for.</p>
                    <ul className="arrow-v-list-1">
                      <li><b>Latest Alerts:</b> Stay informed about high-value scholarship opportunities.</li>
                      <li><b>Application Assistance:</b> Guidance on the application process and essay writing for scholarships.</li>
                    </ul>
                    <h4 className="display-6" id="InternshipOpportunities">Internship Opportunities</h4>
                    <p>Gaining practical experience through internships enhances your academic profile. We recommend programs that offer guaranteed internships, providing valuable industry exposure.</p>
                    <ul className="arrow-v-list-1">
                      <li><b>Placement Guidance: </b>Information on paid and unpaid internships, including placement periods and stipends.</li>
                    </ul>
                    <h4 className="display-6" id="EducationLoanAssistance">Education Loan Assistance</h4>
                    <p>We facilitate access to education loans through partnerships with leading banks and financial institutions, ensuring that financial constraints do not hinder your academic pursuits.</p>

                    <ul className="arrow-v-list-1">
                      <li><b>Customized Financial Solutions: </b>Structuring loans to suit your chosen university and course.</li>
                      <li><b>Hassle-Free Documentation:</b> Simplified processes to ease your loan application experience.</li>
                    </ul>
                    <h4 className="display-6" id="VisaProcessingSupport">Visa Processing Support</h4>
                    <p>Securing a student visa is a critical step in your study abroad journey. Our visa experts provide comprehensive support to ensure a successful outcome.</p>
                    <ul className="arrow-v-list-1">
                      <li><b>Document Preparation:</b> Detailed guidance on the necessary documentation for your visa application.</li>
                      <li><b>Mock Interviews:</b> Preparation sessions to help you confidently face visa interviews.</li>
                    </ul>
                    <h4 className="display-6" id="AlliedServices" ref={scrollEndRef}>Allied Services</h4>
                    <p>To ensure a smooth transition to your new environment, we offer additional services such as accommodation booking, remittance processes, and assistance with foreign exchange transactions.</p>
                    <ul className="arrow-v-list-1">
                      <li><b>Pre-Departure Briefing:</b> Equip yourself with essential information before you embark on your journey.</li>
                    </ul>
                    <p>At FlyUrDream, we are dedicated to turning your study abroad dreams into reality. Our comprehensive services are designed to support you at every stage, ensuring a successful and enriching international education experience.</p>

                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
      <div className="bg-wl-br pt-5 c-form-1">
        <div className="d-flex align-items-center bd-highlight mb-3 container" >

          <div className="p-2 bd-highlight col-md-6 col-sm-12">
            <span className="text-c-2">Let’s Connect</span>
            <h4 className="display-6">Send Your Message
            </h4>

            <p>Please feel free to get in touch with us using the contact form below.

            </p>

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col">
                  <label className="form-label">Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    placeholder="Phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <label className="form-label">Country</label>
                  <select
                    className="form-select"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Choose Country
                    </option>
                    <option value="USA">USA</option>
                    <option value="CANADA">Canada</option>
                  </select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <label className="form-label">Message</label>
                  <textarea
                    className="form-control"
                    name="message"
                    placeholder="Message"
                    style={{ height: "100px" }}
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col text-end">
                  <button type="submit" className="btn btn-primary">
                    <i className="ri-telegram-2-fill"></i>  Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="p-2 bd-highlight col-md-6 col-sm-12 text-c-3 c-form-1-content "><p className="display-6 pl-30">Our services are tailored to help you achieve your academic goals while ensuring a smooth and stress-free process. Whether you need advice on choosing the perfect program, assistance with your application, or support with visas and accommodation, our team of experts is here for you.</p></div>

        </div>

      </div>
      {/* <div className="bg-wl-br pt-5">
        <div className="container-fluid">
          <div className="container">

            <div className="row">
             
              <div className="col-md-6 col-sm-12 ">
               <span className="text-c-2">Let’s Connect</span> 
                <h4 className="display-6">Send Your Message
                </h4>
                
                <p>Please feel free to get in touch with us using the contact form below.

                </p>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <label className="form-label">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Country</label>
                      <select
                        className="form-select"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Choose Country
                        </option>
                        <option value="USA">USA</option>
                        <option value="CANADA">Canada</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Message</label>
                      <textarea
                        className="form-control"
                        name="message"
                        placeholder="Message"
                        style={{ height: "100px" }}
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col text-end">
                      <button type="submit" className="btn btn-primary">
                      <i className="ri-telegram-2-fill"></i>  Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6 col-sm-12 text-c-3 ">
              
              
<p className="display-6 text-center">Our services are tailored to help you achieve your academic goals while ensuring a smooth and stress-free process. Whether you need advice on choosing the perfect program, assistance with your application, or support with visas and accommodation, our team of experts is here for you.</p>
</div>
            </div>

          </div>
        </div>
        <ToastContainer />
      </div> */}
    </div>
  );

};

export default StudentPage;
