import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import StudentPage from "./components/Student/student";
import Privacy from "./components/PrivacyPolicy/privacy";
import CompanyPage from "./components/Company/company";
import RecruitmentPartner from "./components/Recruitment/recruitment";
import CourseSearch from "./components/CourseSearch/course-search";
import StudentCRM from "./components/StudentCRM/student-crm";
import StreamlinedApplicationSolutions from "./components/StreamlinedApplicationSolutions/streamlined-application-solutions";
import PartnersSupportTraining from "./components/PartnersSupportTraining/partners-support-training";
import InstitutionList from "./components/InstitutionList/institution-list";
import InstitutionPage from "./components/Institutions/institution";
import GlobalReachLocalImpact from "./components/GlobalReachLocalImpact/global-reach-local-impact";
import ExcellenceThroughQuality from "./components/ExcellenceThroughQuality/excellence-through-quality";
import StoriesofTransformation from "./components/StoriesofTransformation/storiesof-transformation";
import FranchisePage from "./components/Franchise/franchise";
import CookiePolicy from "./components/CookiePolicy/cookie";
import Contact from "./components/ContactUs/contact";
import FAQ from "./components/FAQ/faq";
import Payment from "./components/Payment/payment";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css"
// Import Bootstrap JavaScript
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/Footer/footer";



function App() {
  return (
    <div className="App">
      <Router>
      <Navbar />  {/* Common Navbar */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/student" element={<StudentPage />} />
          <Route path="/company-page" element={<CompanyPage />} />
          <Route path="/recruitment-partner" element={<RecruitmentPartner />} />
          <Route path="/course-search" element={<CourseSearch />} />
          <Route path="/student-crm" element={<StudentCRM />} />
          <Route path="/streamlined-application-solutions" element={<StreamlinedApplicationSolutions />} />
          <Route path="/partners-support-training" element={<PartnersSupportTraining />} />
          <Route path="/institution-list" element={<InstitutionList />} />
          <Route path="/institutions-page" element={<InstitutionPage />} />
          <Route path="/global-reach-local-impact" element={<GlobalReachLocalImpact />} />
          <Route path="/excellence-through-quality" element={<ExcellenceThroughQuality />} />
          <Route path="/storiesof-transformation" element={<StoriesofTransformation />} />
          <Route path="/franchise-page" element={<FranchisePage />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="/payment-terms" element={<Payment />} />
        </Routes>        
      < Footer />
      </Router>
    </div>
  );
}

export default App;
