import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../Footer/footer";
import "remixicon/fonts/remixicon.css";
import "bootstrap/dist/css/bootstrap.min.css";
import imageFaq from "../../assets/page-title-3.jpg";

const FAQ = () => {
  return (
    <div>
    <div className="inner-banner-section">
        <div className="inner-banner-mask"></div>
      <img src={imageFaq} alt="Company" className="img-fluid"/>
      <div className="inner-banner-caption">
      <h2 className="display-5">FAQ's - Fly Ur Dream</h2>
      <p></p>
      </div>
    </div>
{/*  
        <div className="osc-section pt-5 pb-5">
          <div className="container-fluid">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-title">
                    <h2 className="display-5">
                      FAQ's <span className="text-c-2">Fly Ur Dream</span>
                    </h2>
                  </div>
                  <p className="text-center">
                    Welcome to our FAQ page! Here, you'll find quick and
                    informative answers to common questions about our products,
                    services, policies, and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="common-section pt-5">
      <div className="container-fluid">
        <div className="container">
      
          <div className="row">
           <div className="col-md-12">
            
            <div className="accordion accordion-flush faqs-accordion" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    Q1. Am I eligible to study abroad?
                  </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">To begin studying as an international student, there are a range of entry requirements you may have to meet. The academic requirements (including evidence of English language skills) you need ...</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    Q2. How much will it cost to study abroad?
                  </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">The costs associated with studying at a university or school abroad vary greatly. It depends on the nature of your chosen program, the length of your stay, the distance you ...</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    Q3. When should I start planning my study abroad procedure?
                  </button>
                </h2>
                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    We recommend you to start as early as possible. This will give you enough time to ponder upon your interests and research according to your personal and academic goals and ...
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFour">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    Q4. How long does the application process take?
                  </button>
                </h2>
                <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    We recommend you begin your application process at least a year in advance to give enough time for your applications to be processed and to prepare for your time overseas.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFive">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                    Q5. What if my parents don’t want me to go overseas?
                  </button>
                </h2>
                <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    It’s natural for your family to be nervous about letting their child travel alone to a new country. Millions of students have done so and thrived, but this doesn’t make ...
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingSix">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                    Q6. What if I can’t afford to study overseas?
                  </button>
                </h2>
                <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    There are various scholarships and bursaries available for international students to help them fund their studies. The amount and availability varies by the level of study and institution. Since scholarships ...
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingSeven">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                    Q7. Can I work and study at the same time during studying abroad?
                  </button>
                </h2>
                <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Working while you study can complement your educational and living experience when studying abroad. If you pursue a course at a degree level or above you may be permitted to ...
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingEight">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                    Q8. Will studying abroad help me find a good job?
                  </button>
                </h2>
                <div id="flush-collapseEight" className="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Your career prospects will benefit hugely from your experience of studying, living and socialising abroad. It’s your opportunity to develop a wealth of new skills, perspectives and stronger English language ...
                  </div>
                </div>
              </div>
            </div>
            
           </div>
            
          </div>
        </div>
      </div>
    </div>
      

      {/* Footer */}
    </div>
  );
};

export default FAQ;
