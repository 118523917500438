import React from 'react';
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imageCompany from "../../assets/partners-support-training_banner.png";

const PartnersSupportTraining = () => {
  return (
    <div >
    <div className="inner-banner-section">
    <div className="inner-banner-mask"></div>
    <img src={imageCompany} alt="Company" className="img-fluid" />
    <div className="inner-banner-caption">
      <h2 className="display-5">Partners Support & training</h2>
      
    </div>
  </div>
  <div className="osc-section pt-5 pb-5">
  <div className="container-fluid">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
        <div className="section-title">
                  <h2 className="display-5">
                    {" "}
                    Empowering Your Success <span className="text-c-2">Your Team</span>,<br/> Backed by Our Expertise
                  </h2>
                </div>
          <p className="text-center">
          We take on the complexities, so you can focus on what matters most—helping your students succeed. With 24/7 support, tailored training, and personalized guidance, we ensure you’re always one step ahead in delivering exceptional results.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div className='common-section pt-5'>
    <div className="container-fluid">
        <div className="container">
        <h4 className="display-6">Comprehensive Support for Partners</h4>
        <p>We provide end-to-end support to make your work seamless and effective:</p>
            <ul className="arrow-v-list-1">
                <li><b>Customer Support:</b> Prompt assistance whenever you need it, ensuring smooth operations.</li>
                <li><b>Counsellor Training: </b>Stay updated with regular training sessions conducted by destination and institution experts.</li>
                <li><b>Admissions Support:</b> From understanding entry requirements to selecting institutions, our Admissions team guides you through every step.</li>
                <li><b>Quality, Compliance Guidance:</b> Achieve success with personalized support for application processes and visa requirements.</li>
                <li><b>Account management Support:</b> Simplify account management and streamline commission payments with our expert assistance.</li>
                
            </ul>
            <h4 className="display-6 pt-3">Localized Expertise</h4>
                
                <p>Every country has its unique challenges. Our in-country teams provide insights and personalized guidance to help you navigate specific requirements with ease.

</p>
<h4 className="display-6">Real-Time Support, 24/7</h4>
<p>Never wait for answers. Our dedicated helpdesk and in-platform messaging system provide instant support around the clock, ensuring uninterrupted productivity.</p>
<h4 className="display-6">Empowered Counsellors, Thrilled Students</h4>
<p>With knowledge from our destination and institution specialists, your counsellors will exceed expectations, delivering top-tier guidance that leaves students confident and satisfied.</p>
                
                
               
        </div>
    </div>
</div>
</div>
  );
};

export default PartnersSupportTraining