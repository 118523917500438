import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imageCompany from "../../assets/company.png";
import 'remixicon/fonts/remixicon.css';

const Privacy = () => {
  return (
    <div >
      <div className="inner-banner-section">
      <div className="inner-banner-mask"></div>
    <img src={imageCompany} alt="Company" className="img-fluid"/>
    <div className="inner-banner-caption">
      <h2 className="display-5">Flyurdream Privacy Notice
      </h2>
    </div>
  </div>
  
    <div className="osc-section pt-5 pb-5">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 className="display-5"> Flyurdream Privacy Notice <span className="text-c-2">Introduction</span></h2>
              </div>
              <p className="text-center">
                FLYURDREAM LTD provides UK university, college and boarding school advice and application services. At FLYURDREAM the privacy and security of our partners, customers and website visitors are of paramount importance. FLYURDREAM is committed to protecting the data you share with us. This privacy policy explains how FLYURDREAM processes information that can be used to directly or indirectly identify an individual (“Personal Data”) collected through use of our websites.

For the purposes of this policy, FLYURDREAM defines the term “User” as an entity with which FLYURDREAM has an established relationship, and the term “Visitor” as an individual that visits our front-end website (for example www.flyurdream.com).
              </p>
              <p className="text-center">Any information stored on FLYURDREAM ’s servers is treated as confidential. All information is stored securely and is accessed by authorized personnel only. FLYURDREAM implements and maintains appropriate technical, security and organisational measures to protect Personal Data against unauthorized or unlawful processing and use, and against accidental loss, destruction, damage, theft or disclosure.

                Collection and use</p>
            </div>
          </div>
         
        </div>
      </div>
    </div>
    <div className="common-section pt-5">
      <div className="container-fluid">
        <div className="container">
          
          <div className="row">
           <h4 className="display-6">2.1 General

           </h4>
            <p>The following sections cover the specifics of each of the three groups from which data is collected: website Visitors, Respondents and Users.

            </p>
            <h4 className="display-6">2.2 Website Visitors

            </h4>
            <p>If you are a Visitor to our website, whether or not a registered client with <a href="https://flyurdream.com/">FLYURDREAM </a> , then this section is relevant for you.

              By visiting this website, you consent to the collection and use of your Personal Data as described herein. If you do not agree with the terms set out herein, please do not visit this website. If required by applicable law, we will seek your explicit consent to process Personal Data collected on this website or volunteered by you. Note that any consent will be entirely voluntary. However, if you do not grant the requested consent to the processing of your Personal Data, the use of this website may not be possible.
              
              <a href="https://flyurdream.com/">FLYURDREAM </a> may collect, record and analyze information of Visitors to its website. We may record your IP address and use cookies that you opt in to receiving. <a href="https://flyurdream.com/">FLYURDREAM </a> may add information collected by way of pageview activity. Furthermore, <a href="https://flyurdream.com/">FLYURDREAM </a> may collect and process any Personal Data that you volunteer to us in our website’s forms, such as when you register for a consultation or sign up for events and newsletters. If you provide <a href="https://flyurdream.com/">FLYURDREAM </a> with your social media details, <a href="https://flyurdream.com/">FLYURDREAM </a> may retrieve publicly available information about you from social media.</p>
              <p>Such Personal Data may comprise your IP address, first and last name, your postal and email address, your telephone number, data for social networks, your areas of interest, interest in <a href="https://flyurdream.com/">FLYURDREAM </a> products and services, and information as to the type of relationship that exists between <a href="https://flyurdream.com/">FLYURDREAM </a> and yourself.

                <a href="https://flyurdream.com/">FLYURDREAM </a> gathers data about visits to the website, including numbers of Visitors and visits, geolocation data, length of time spent on the site, pages clicked on or where Visitors have come from.</p>
            <h4 className="display-6">2.2.1 Purpose of processing personal data

            </h4>
            <p><a href="https://flyurdream.com/">FLYURDREAM </a> uses the collected data to communicate with Visitors, to customize content for Visitors, to show ads on other websites to Visitors, and to improve its website by analysing how Visitors navigate its website.</p>
            <h4 className="display-6">2.2.2 Sharing personal data</h4>
            <p><a href="https://flyurdream.com/">FLYURDREAM </a> may also share such information with service vendors or contractors in order to provide a requested service or transaction or in order to analyze the Visitor behavior on its website.</p>
            <h4 className="display-6">2.2.3 Cookies</h4>
            <p>We ask for your consent before placing third party cookies on your device.</p>
            <h4 className="display-6">2.2.4 Links to other sites</h4>
            <p>Please be aware that while visiting our site, Visitors can follow links to other sites that are beyond our sphere of influence. <a href="https://flyurdream.com/">FLYURDREAM </a> is not responsible for the content or privacy policy of these other sites.</p>
            <h4 className="display-6">2.3 Enquiries and Users and 2.3.1 General</h4>
            <p>In order to provide UK University application advice and services to its Users, <a href="https://flyurdream.com/">FLYURDREAM </a> collects certain types of data from them. This section will describe how this data is collected and used by <a href="https://flyurdream.com/">FLYURDREAM </a> .</p>
            <h4 className="display-6">2.3.2 Collection of User data</h4>
            <p>During a User’s registration, they provide information such as name, email address, postal address, telephone number, and other relevant data. This information is used by <a href="https://flyurdream.com/">FLYURDREAM </a> to identify the User and provide them with services regarding UK University application support and advice.<a href="https://flyurdream.com/">FLYURDREAM </a> will not retain User data longer than is necessary to fulfill the purposes for which it was collected or as required by applicable laws or regulations.</p>
            <h4 className="display-6">Retention and Deletion</h4>
            <p><a href="https://flyurdream.com/">FLYURDREAM </a> will not retain data longer than is necessary to fulfill the purposes for which it was collected or as required by applicable laws or regulations.

              Acceptance of these Conditions</p>
              <p>We assume that all Visitors of our website and users of <a href="https://flyurdream.com/">FLYURDREAM </a> services have carefully read this document and agree to its contents. If someone does not agree with this privacy policy, they should refrain from using our website and services. We reserve the right to change our privacy policy as necessity dictates. Continued use of <a href="https://flyurdream.com/">FLYURDREAM </a> websites and services after having been informed of any such changes to these conditions implies acceptance of the revised privacy policy. This privacy policy is an essential part of our terms of use.</p>
            <h4 className="display-6">Our Legal Obligation to Disclose Personal Information</h4>
            <p>We will reveal a user’s personal information without his/her prior permission only when we have reason to believe that the disclosure of this information is required to establish the identity of, to contact or to initiate legal proceedings against a person or persons who are suspected of infringing rights or property belonging to <a href="https://flyurdream.com/">FLYURDREAM </a> or to others who could be harmed by the user’s activities or of persons who could (deliberately or otherwise) transgress upon these rights and property. We are permitted to disclose personal information when we have good reason to believe that this is legally required.</p>
            <h4 className="display-6"><a href="https://flyurdream.com/">FLYURDREAM </a> ’s Data Protection Officer</h4>
            <p><a href="https://flyurdream.com/">FLYURDREAM </a> has a “Data Protection Officer” who is responsible for matters relating to privacy and data protection. This Data Protection Officer can be reached at the following address:</p>
            <h4 className="display-6"><a href="https://flyurdream.com/">FLYURDREAM </a> LTD</h4>
            <p>Attn: Data Protection Officer

              Vista Business Centre, 50 Salisbury Road, Hounslow, England, TW4 6JQ
              
              Dpo@flyurdream.com</p>
            <h4 className="display-6">For Further Information</h4>
            <p>If you have any further questions regarding the data <a href="https://flyurdream.com/">FLYURDREAM </a>collects, or how we use it, then please feel free to contact us by email at: admissions@flyurdream.com, or in writing at:

              Vista Business Centre, 50 Salisbury Road, Hounslow, England, TW4 6JQ</p>
            
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Privacy;
