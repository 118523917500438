import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imagePartner1 from "../../assets/FranchiseAtFlyUrDream.jpg";
import imagePartner2 from "../../assets/franchise_1.png";
import imagePartner3 from "../../assets/franchise_banner.png";
import 'remixicon/fonts/remixicon.css';

const FranchisePage = () => {
  return (
    <div>
        <div className="institutions-banner-section">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="video-frame">
          <img src={imagePartner3} className="d-block w-100" alt="..." />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="video-content text-c-1">
            <h4 className="display-6">Franchise</h4>
            <p>At FlyUrDream, we are committed to empowering aspiring entrepreneurs to make a meaningful impact in the field of international education. Our franchise program offers a unique opportunity to become part of a dynamic and rapidly growing industry, providing comprehensive support and resources to ensure your success.</p>
            <div className="row">
                <div className="col text-center count-box">
                  <h4>500+</h4>
                  <p>Partners </p>
                </div>
                <div className="col text-center count-box">
                  <h4>8+</h4>
                  <p>Locations </p>
                </div>
                <div className="col text-center count-box">
                  <h4>10k</h4>
                  <p>Success Students </p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="in-banner-layer">
      
    </div>
    
  </div>
    <div className="osc-section pt-5 pb-5">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="img-box-1">
                <img src={imagePartner1} className="img-fluid" alt=""/>
                {/* <div className="img-box-1-content">
                  <h5>Why Choose a FlyUrDream Franchise? </h5>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      Established Brand Recognition
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">Leverage the credibility and trust of the FlyUrDream brand, known for excellence in student recruitment and educational consulting.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      Comprehensive Training and Support
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">Benefit from extensive training programs covering all aspects of the business, including marketing, operations, and student counseling. Our ongoing support ensures you are well-equipped to manage and grow your franchise.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      Proven Business Model
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      Utilize our successful business framework, designed to maximize efficiency and profitability, allowing you to focus on delivering exceptional services to students.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                      Access to Exclusive Resources
                    </button>
                  </h2>
                  <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      Gain entry to our proprietary tools and platforms, including advanced CRM systems and marketing materials, to streamline your operations and enhance client engagement.
                    </div>
                  </div>
                </div>
               
                
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div className="common-section  bg-bl-wr  text-c-1">
      {/* <div className="half-img-franchise-right">
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 pt-5 pb-5">
            <h4 className="display-6">Franchisee Requirements:</h4>
              <ul className="arrow-v-list-1">
                <li><b>Passion for Education:</b>  A genuine interest in helping students achieve their academic goals and a commitment to providing high-quality services.</li>
                <li><b> Business Acumen:</b> Strong management skills and the ability to operate a business effectively within the education sector.</li>
                <li><b> Financial Investment:</b> Adequate capital to invest in the franchise, covering initial setup costs and working capital requirements.</li>
                <li>
                  <b>Commitment to Excellence:</b> Dedication to upholding the FlyUrDream standards and delivering exceptional service to students and partners.
                </li>
              </ul>
              <h4 className="display-6">Join the FlyUrDream Family</h4>
              <p>Embarking on a FlyUrDream franchise is more than a business venture; it’s an opportunity to make a positive impact on students’ lives while building a successful enterprise. With our unwavering support and a robust network, you will be well-positioned to thrive in the international education industry.

              </p>
              <h4 className="display-6">Take the Next Step</h4>
              <p>If you are ready to explore this exciting opportunity and become a part of the FlyUrDream family, we invite you to contact us for more information. Let’s work together to make educational dreams a reality.</p>
          </div>
          <div className="col-md-6 col-sm-12 text-center pt-150 remove-pt-150-767">
          <img src={imagePartner2} className="img-fluid"/>
          </div>
          
        </div>
      </div>
    </div>
    </div>
  );
};

export default FranchisePage;
