import React from "react";
import imageCompany from "../../assets/company.png";
import imageTeam1 from "../../assets/team-1.jpg";
import pageTitleImg from "../../assets/page-title-7.jpg"; // Import the image relative to company.js
import 'remixicon/fonts/remixicon.css';

const CompanyPage = () => {
  return (
    <div>
      <div className="inner-banner-section">
        <div className="inner-banner-mask"></div>
        <img src={pageTitleImg} alt="Company" className="img-fluid" />
        <div className="inner-banner-caption">
          <h2 className="display-5">Company</h2>
          <p>At FlyUrDream, we are dedicated to transforming students’ aspirations <br />into reality by providing comprehensive support throughout</p>
        </div>
      </div>
      <div className="osc-section pt-5 pb-5">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h2 className="display-5"> Our successful <span className="text-c-2">700 Clients</span>...<br></ br>
                    Flyurdream here to help grow faster</h2>
                </div>
                <p className="text-center">
                  At FlyUrDream, we are dedicated to transforming students’ aspirations into reality by providing comprehensive support throughout their international education journey. Our mission is to simplify the complexities of studying abroad, ensuring that every student has access to quality education opportunities worldwide.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col border c-box text-center">
                <i className="ri-eye-fill"></i>
                <h4 className="display-6">Our Vision</h4>
                <p>We envision a world where every student, regardless of background, has the opportunity to pursue their academic dreams on a global stage. By bridging the gap between students and international institutions, we aim to foster a diverse and inclusive educational environment that benefits all.</p>
              </div>
              <div className="col border c-box text-center">
                <i className="ri-pass-valid-fill"></i>
                <h4 className="display-6">Our Mission</h4>
                <p>Our mission is to empower students by providing personalized guidance, resources, and support, enabling them to make informed decisions about their education and future careers. We strive to be the trusted partner in every student’s journey toward academic excellence and personal growth.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="common-section pt-5">
        <div className="container-fluid">
          <div className="container">
            <div className="section-title">
              <h6>Our Story </h6>
              <h2 className="display-5"> Founded in 2010</h2>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <p>Founded in 2010, FlyUrDream began as a small consultancy with a passion for helping students navigate the complexities of international education. Over the years, we have grown into a global organization, assisting thousands of students in realizing their academic ambitions. Our success is built on a foundation of integrity, dedication, and a commitment to excellence.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="common-section mt-5">
        <div className="container-fluid">
          <div className="container">
            <div className="section-title">
              <h6>Our Services</h6>
              <h2 className="display-5"> We offer tailored advice to help students</h2>
            </div>
            <div className="row pt-3">
              <div className="col-md-6 col-sm-12 ">
                <div className="shadow-sm  p-3   bg-light">
                  <h4>Personalized Counseling</h4>
                  <p>We offer tailored advice to help students choose the right courses and institutions that align with their goals.</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className="shadow-sm  p-3 bg-light">
                  <h4>Application Assistance</h4>
                  <p>Our team guides students through the application process, ensuring all requirements are met for a successful submission.</p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 col-sm-12">
                <div className="shadow-sm  p-3   bg-light">
                  <h4>Visa Support</h4>
                  <p>We provide comprehensive assistance with visa applications, including document preparation and interview coaching.</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className="shadow-sm  p-3  bg-light">
                  <h4>Pre-Departure Briefings</h4>
                  <p>To prepare students for their new environment, we offer sessions covering cultural adaptation, accommodation, and other essential aspects of studying abroad.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="common-section pt-5">
        <div className="container-fluid">
          <div className="container">
            <div className="section-title">
              <h6>Our Global Presence</h6>
              <h2 className="display-5">With a commitment to accessibility and support, FlyUrDream has established a robust global presence:</h2>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col bg-2 text-c-1 pt-3 pb-3">
                United Kingdom | United Arab Emirates  | India
              </div>
              <div className="col ">
                <p>Our strategically located offices enable us to provide localized support and resources to students across different regions, ensuring a seamless experience throughout their educational journey.</p>
              </div>

            </div>



          </div>
        </div>
      </div>
      <div className="common-section mt-5 bg-3 pt-5 pb-5 text-c-1">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <h4 className="display-6">Our Team</h4>
              <p>Our team comprises experienced professionals who are passionate about education and dedicated to student success. With backgrounds in international education, counseling, and admissions, our advisors are equipped to provide insightful guidance tailored to each student’s unique needs.</p>
              <h4 className="display-6">Join the FlyUrDream Family</h4>
              <p>At FlyUrDream, we believe that every student has the potential to achieve greatness. We are here to support and guide you every step of the way. Let us help you turn your dreams into reality.</p>
            </div>
            <div className="col-md-6 col-sm-12">
              <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <button className="carousel-control-prev border-1 shadow-sm " type="button"
                  data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next border-1 shadow-sm " type="button"
                  data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row ">
                      <div className="col-md-6 ">
                        <div className="team-box">
                          <img src={imageTeam1} alt="Team" />
                          <div className="team-box-name">

                            <h6>Anthom Bu Spar</h6>
                            <span>Marketing Manager</span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="team-box">
                          <img src={imageTeam1} alt="Team" />
                          <div className="team-box-name">

                            <h6>Anthom Bu Spar</h6>
                            <span>Marketing Manager</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row ">
                      <div className="col-md-6 ">
                        <div className="team-box">
                          <img src={imageTeam1} alt="Team" />
                          <div className="team-box-name">

                            <h6>Anthom Bu Spar</h6>
                            <span>Marketing Manager</span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="team-box">
                          <img src={imageTeam1} alt="Team" />
                          <div className="team-box-name">

                            <h6>Anthom Bu Spar</h6>
                            <span>Marketing Manager</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CompanyPage;
