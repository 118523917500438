import React from 'react';
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imageCompany from "../../assets/excellence-through-quality_banner.png";
const ExcellenceThroughQuality = () => {
  return (
    <div >
    <div className="inner-banner-section">
    <div className="inner-banner-mask"></div>
    <img src={imageCompany} alt="Company" className="img-fluid" />
    <div className="inner-banner-caption">
      <h2 className="display-5">Excellence Through Quality</h2>
      <p>Your Trusted Partner in Admissions Success
      </p>
    </div>
  </div>
  <div className="osc-section pt-5 pb-5">
  <div className="container-fluid">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          
          <p className="text-center">
          Flyurdream simplifies the admissions process by providing unmatched quality and compliance services. Let us take the stress out of application reviews, so your team can focus on enrolling top-tier students. Say goodbye to incomplete or fraudulent applications and hello to seamless, efficient processes.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div className='common-section pt-5'>
    <div className="container-fluid">
        <div className="container">
        <h4 className="display-6">Our Impact</h4>
        <h5>Exceptional Service Levels</h5>
            <ul className="arrow-v-list-1">
                <li><b>90% Application-to-Offer Rate:</b> Rigorous checks and optimized workflows deliver consistent, high success rates.</li>
                <li><b>95% Global Visa Success Rate:</b> Trusted by institutions worldwide for our expertise in visa processes.</li>
                <li><b>1,000+ Institutions Globally:</b> Partnered with leading universities, colleges, and schools across the globe.</li>
                
            </ul>
            <h4 className="display-6 pt-3">Quality Assurance: A-Grade Applicants, Every Time</h4>
               
            <ul className="arrow-v-list-1">
	<li><b>Comprehensive Application Checks:</b> Each application undergoes thorough reviews to confirm accuracy, authenticity, and completeness.</li>
	<li><b>Visa Application Expertise:</b> Our tailored compliance guidance helps students navigate complex visa requirements with confidence.</li>
	<li><b>Streamlined Processes:</b> Minimize your team’s workload with meticulously prepared and ready-to-submit applications.</li>
	
</ul>

            
            <h5 className="pt-3">Obsessed with Quality, Driven by Results</h5>
            <p>We’re more than a service provider; we’re your partner in excellence. Our commitment to quality ensures you receive the best-fit students while maintaining a seamless admissions experience.</p>
        </div>
    </div>
</div>
</div>
  );
};

export default ExcellenceThroughQuality;