import React from "react";
import { Link } from 'react-router-dom';
import appLogo from "../../assets/flyurdream_logo.png";
import 'remixicon/fonts/remixicon.css';

const Navbar = () => {
  return (
    <div className="header">
      <div className="header-top"></div>
      <div className="container-fluid">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light ">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">
                <img src={appLogo} alt="logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="/"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/student">
                      {" "}
                      Students
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/recruitment-partner">
                      {" "}
                      Recruitment Partners
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/institutions-page">
                      Institutions{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/franchise-page">
                      Franchise{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/company-page">
                      Company{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link ext-link" href="#">
                      Book Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link ext-link" href="#">
                      Login
                    </a>
                  </li>
                </ul>
                <ul className="nav-ext-item">
                  <li className="nav-item">
                    <a className="nav-link ext-link" href="#">
                      Book Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link ext-link"
                      href="https://crmfud.azurewebsites.net/"
                    >
                      Login
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
