import React from "react";
import Navbar from "../navbar/Navbar";
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import imagecanada from "../../assets/canada.png";
import imageuk from "../../assets/united-kingdom.png";
import imagefrance from "../../assets/france.png";
import imageAus from "../../assets/australia.png";
import imageItaly from "../../assets/italy.png";
import imagenz from "../../assets/nz.png";
import imageSpain from "../../assets/spain.png";
import imagegermany from "../../assets/germany.png";
import imagechina from "../../assets/china.png";
import imageOne from "../../assets/banner-1.jpg";
import imageusa from "../../assets/united-states.png";
import imageTwo from "../../assets/banner-2.jpg";
import imageThree from "../../assets/banner-3.jpg";
import imageCost from "../../assets/Cost-Effective-01.jpg";
import imageProcesser from "../../assets/Faster_Processing.jpg";
import imageInterview from "../../assets/DirectInterviews_one.png";
import imageVisa from "../../assets/VisaAssistance-01.jpg";
import imageindia from "../../assets/india.png"
import imageuae from "../../assets/uae.png"
import imageghana from "../../assets/ghana.png"
import imagepakistan from "../../assets/pakistan.png"
import imagesrilanka from "../../assets/srilanka.png"
import imagebangladesh from "../../assets/bangladesh.png"
import imagenepal from "../../assets/nepal.png"
import Footer from "../Footer/footer";

const Home = () => {
  return (
    <div>
      <div className="banner-section">
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={imageOne} className="d-block w-100" alt="..."/>
          <div className="carousel-caption d-none d-md-block">
            <h5 className="display-4">Make your overseas dreams            </h5>
            <p>come true!</p>
          </div>
        </div>
        <div className="carousel-item">
          <img src={imageTwo} className="d-block w-100" alt="..."/>
          <div className="carousel-caption d-none d-md-block">
            <h5 className="display-4">Make your overseas dreams            </h5>
            <p>come true!</p>
          </div>
        </div>
        <div className="carousel-item">
          <img src={imageThree} className="d-block w-100" alt="..."/>
          <div className="carousel-caption d-none d-md-block">
            <h5 className="display-4">Make your overseas dreams            </h5>
            <p>come true!</p>
          </div>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </div>
      <div className="osc-section pt-5 pb-5">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h2 className="display-5">
                    {" "}
                    Our successful <span className="text-c-2">700 Clients</span>...
                    <br />
                    Flyurdream here to help grow faster
                  </h2>
                </div>
                <p className="text-center">
                  Flyurdream is an International Student
                  Recruitment Organization that assists students in pursuing
                  overseas education in universities in UK, USA, Australia, New
                  Zealand, Canada, Switzerland, etc. Flyurdream is a
                  solution-oriented company, which specializes in tailoring
                  career pathways to suit individual profiles within the
                  constraints that may prevail in each case. The constraints may
                  be educational, financial or personal.
                </p>
              </div>
            </div>
            <div className="row" >
              <div className="col-md-12">
                <ul className="osc-section-list">
                  <li>
                    <div className="shadow-sm p-4 mb-4  text-center" href="student">
                    <a className="icon-home" href="/student">
                      <i className="ri-user-2-fill"></i>
                      </a>
                      <h2 className="display-6">Students</h2>
                      {/* <p>
                        Dreaming of studying abroad? At FlyUrDream, we make your
                        international education journey{" "}
                      </p> */}
                      <a href="/student">
                        <i className="ri-arrow-right-double-line"></i>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="shadow-sm p-4 mb-4  text-center" href="/recruitment-partner">
                    <a className="icon-home" href="/recruitment-partner">
                      <i className="ri-shake-hands-fill"></i>
                      </a>
                      <h2 className="display-6">Recruitment Partners</h2>
                      {/* <p>
                        Dreaming of studying abroad? At FlyUrDream, we make your
                        international education journey{" "}
                      </p> */}
                      <a href="/recruitment-partner">
                        <i className="ri-arrow-right-double-line"></i>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="shadow-sm p-4 mb-4  text-center" href="/institutions-page">
                    <a className="icon-home" href="/institutions-page">
                      <i className="ri-bank-fill"></i>
                      </a>
                      <h2 className="display-6">Institutions</h2>
                      {/* <p>
                        Dreaming of studying abroad? At FlyUrDream, we make your
                        international education journey{" "}
                      </p> */}
                      <a href="/institutions-page">
                        <i className="ri-arrow-right-double-line"></i>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="shadow-sm p-4 mb-4  text-center">
                    <a className="icon-home" href="/franchise-page">
                      <i className="ri-briefcase-4-fill"></i>
                      </a>
                      <h2 className="display-6">Franchise</h2>
                      {/* <p>
                        Dreaming of studying abroad? At FlyUrDream, we make your
                        international education journey{" "}
                      </p> */}
                      <a href="/franchise-page">
                        <i className="ri-arrow-right-double-line"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bct-section pt-5">
        <div className="container-fluid">
          <div className="container">
            <div className="section-title">
              <h6>Countries We Offer </h6>
              <h2 className="display-5"> Best Countries to Travel</h2>
            </div>
            <div className="row">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="Education"
                  role="tabpanel"
                  aria-labelledby="Education-tab"
                >
                  <div>
                  <ul className="country-list">
                      <li>
                        <a>
                          <img src={imageusa} alt="" /> United
                          States
                        </a>
                      </li>
                      <li>
                        <a>
                          <img src={imagecanada} alt="" /> canada
                        </a>
                      </li>
                      <li>
                        <a>
                          <img src={imageuk} alt="" /> United
                          Kingdom
                        </a>
                      </li>
                      <li>
                        <a>
                          <img src={imagefrance} alt="" /> France
                        </a>
                      </li>
                      <li>
                        <a>
                          <img src={imagegermany} alt="" /> Germany
                        </a>
                      </li>
                      {/* <li>
                        <a href="javascript:void(0)">
                          <img src={imagechina} alt="" /> China
                        </a>
                      </li> */}
                      <li>
                        <a>
                          <img src={imageAus} alt="" />{" "}
                          Australia
                        </a>
                      </li>
                      <li>
                        <a>
                          <img src={imagenz} alt="" /> New Zealand
                        </a>
                      </li>
                      <li>
                        <a>
                          <img src={imageItaly} alt="" /> Italy
                        </a>
                      </li>
                      <li>
                        <a>
                          <img src={imageSpain} alt="" /> Spain
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="Travel"
                  role="tabpanel"
                  aria-labelledby="Travel-tab"
                >
                  <div>
                    <ul className="country-list">
                      <li>
                      <a >
                          <img src="images/united-states.png" alt="" /> United
                          States
                        </a>
                      </li>
                      <li>
                      <a >
                          <img src="images/canada.png" alt="" /> canada
                        </a>
                      </li>
                      <li>
                      <a >
                          <img src="images/united-kingdom.png" alt="" /> United
                          Kingdom
                        </a>
                      </li>
                      <li>
                      <a >
                          <img src="images/france.png" alt="" /> France
                        </a>
                      </li>
                      <li>
                      <a >
                          <img src="images/germany.png" alt="" /> Germany
                        </a>
                      </li>
                      <li>
                      <a >
                          <img src="images/china.png" alt="" /> China
                        </a>
                      </li>
                      <li>
                      <a >
                          <img src="images/united-states.png" alt="" />{" "}
                          Australia
                        </a>
                      </li>
                      <li>
                      <a >
                          <img src="images/canada.png" alt="" /> New Zealand
                        </a>
                      </li>
                      <li>
                      <a >
                          <img src="images/united-kingdom.png" alt="" /> Italy
                        </a>
                      </li>
                      <li>
                      <a >
                          <img src="images/france.png" alt="" /> Spain
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cr-section mt-5">
        <div className="container-fluid">
          <div className="container">
            <div className="section-title">
              <h6>Student  Reviews</h6>
              <h2 className="display-5"> What our Students are saying</h2>
            </div>
            <div className="row">
              <div className="col-md-12 mb-4 ">
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-6">
                      <button
                        className="carousel-control-prev border-1 shadow-sm "
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                        style={{
                          backgroundColor: "rgb(21, 94, 150)",
                          border: "none",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = " #2c82c4")}
  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "rgb(21, 94, 150)")}>
                      
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <button
                        className="carousel-control-next border-1 shadow-sm "
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                        style={{
                          backgroundColor: "rgb(21, 94, 150)",
                          border: "none",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = " #2c82c4")}
  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "rgb(21, 94, 150)")}>
                      
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>

                  {/* <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="row ">
                        <div className="col-md-6 ">
                          <div className="border shadow-sm p-4 bg-white">
                            Extremely professional in matter of response and
                            have more than sufficient knowledge on most
                            country’s universities and if not, they’ll look up
                            on all the important information in no matter ...
                          </div>
                          <div className="text-end pt-2">Deepak Arora</div>
                        </div>

                        <div className="col-md-6">
                          <div className="border shadow-sm p-4 bg-white">
                            Extremely professional in matter of response and
                            have more than sufficient knowledge on most
                            country’s universities and if not, they’ll look up
                            on all the important information in no matter ...
                          </div>
                          <div className="text-end pt-2">Hari Kiran</div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row ">
                        <div className="col-md-6 ">
                          <div className="border shadow-sm p-4 bg-white">
                            Extremely professional in matter of response and
                            have more than sufficient knowledge on most
                            country’s universities and if not, they’ll look up
                            on all the important information in no matter ...
                          </div>
                          <div className="text-end pt-2">Deepak Arora</div>
                        </div>

                        <div className="col-md-6">
                          <div className="border shadow-sm p-4 bg-white">
                            Extremely professional in matter of response and
                            have more than sufficient knowledge on most
                            country’s universities and if not, they’ll look up
                            on all the important information in no matter ...
                          </div>
                          <div className="text-end pt-2">Hari Kiran</div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row ">
                        <div className="col-md-6 ">
                          <div className="border shadow-sm p-4 bg-white">
                            Extremely professional in matter of response and
                            have more than sufficient knowledge on most
                            country’s universities and if not, they’ll look up
                            on all the important information in no matter ...
                          </div>
                          <div className="text-end pt-2">Deepak Arora</div>
                        </div>

                        <div className="col-md-6">
                          <div className="border shadow-sm p-4 bg-white">
                            Extremely professional in matter of response and
                            have more than sufficient knowledge on most
                            country’s universities and if not, they’ll look up
                            on all the important information in no matter ...
                          </div>
                          <div className="text-end pt-2">Hari Kiran</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div class="carousel-inner">

<div class="carousel-item active">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "They helped me identify the best course, assisted with my GTE statement, and even gave packing tips!"
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagesrilanka} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Chamika Fernando – Studying in Australia</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "I had no idea how to apply to a French university, but they made it super easy."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagebangladesh} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Shamima Akter – Studying in France</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)" }}>
        "Flyurdream helped me secure admission to a top-ranked university in London. Their guidance with SOPs, documentation, and visa interview prep was amazing. I couldn't have done this alone!"
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageindia} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Aisha Verma – Studying in UK</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "I struggled with the blocked account and motivation letter, but they guided me perfectly."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagesrilanka} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Nuwani Jayasuriya – Studying in Germany</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "The support I received was above and beyond – from admission to accommodation."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagesrilanka} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Tharindu Silva – Studying in France</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Super grateful. They helped me with scholarships, visa, and post-arrival info. I'm loving my course in Vancouver!"
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageghana} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Ama Adjei – Studying in Canada</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "What I loved most was how approachable they were. Every silly question was answered patiently."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagesrilanka} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Ishara Senanayake – Studying in USA</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Exceptional service – I was especially impressed by how they handled my blocked account and visa checklist."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageghana} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Nana Kofi – Studying in Germany</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "The counsellors were friendly and very informative. They even helped me find part-time job options once I arrived in Melbourne."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageindia} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Divya Nair – Studying in Australia</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "I appreciated their constant updates and fast response times. Even late-night queries were answered quickly!"
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageuae} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Fatima Al-Zaabi – Studying in UK</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Flyurdream helped me secure admission to a university in Manchester and guided me through my credibility interview prep too."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagenepal} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Anup Ghimire – Studying in UK</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "I had my doubts, but their experienced team made the whole process clear and stress-free."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageghana} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Hira Ali – Studying in USA</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Highly professional service. They helped me every step of the way, especially with my visa documentation."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageindia} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Pooja Patil – Studying in Ireland</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "From helping me shortlist universities to preparing for my F1 visa interview, the team was always there. Super grateful!"
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageindia} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Rohan Sharma – Studying in USA</p>
      </div>
    </div>
  </div>
</div>

<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "I loved their attention to detail, especially with the embassy appointment and document requirements."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagenepal} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Pema Lama – Studying in Germany</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "They helped me apply to five universities and guided me through choosing the best offer. Truly supportive!"
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageghana} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Akosua Mensah – Studying in UK</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "I wasn't sure where to begin, but Flyurdream gave me a clear roadmap. I'm now studying engineering in Toronto!"
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageuae} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Omar Al-Mansoori – Studying in Canada</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Really appreciated the personal attention. They treat you like family!"
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagepakistan} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Nimra Shabbir – Studying in Ireland</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Thanks to Flyurdream, I'm now pursuing my Master's in Paris. The visa process was tricky, but their mock interviews helped a lot."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageghana} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Kwame Boateng – Studying in France</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "I received 3 offers and Flyurdream helped me pick the best one for my career goals."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagepakistan} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Bilal Qureshi – Studying in Canada</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "They walked me through each requirement and helped avoid delays in my application. Really efficient team."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageuae} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Zayd Khan-Mansoori – Studying in Germany</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "I loved how they tailored everything to my needs. They helped polish my SOP and guided me through the CAS process."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagebangladesh} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Tanvir Rahman – Studying in UK</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "My dream of studying abroad came true because of Flyurdream. They're professional and reliable."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagebangladesh} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Rahid Hasan – Studying in Germany</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Every document was reviewed thoroughly, and they helped me avoid common mistakes."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagebangladesh} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Farhana Jahan – Studying in Canada</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Their visa checklist and follow-up calls kept me on track throughout the process."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagebangladesh} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Nabil Chowdhury – Studying in Australia</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "The team was very supportive and helped me find universities that matched my profile and budget. I'm now at a top U.S. college!"
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagenepal} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Sushant Thapa – Studying in USA</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Their support felt personal and genuine. I'd recommend them to every Nepali student dreaming of going abroad."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagenepal} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Kripa Shrestha – Studying in Canada</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "So easy to work with, and they really know how to make your application stand out."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagenepal} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Bibek Karki – Studying in Ireland</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "It's hard to find agents who care about your future, but Flyurdream does. They even followed up after I landed!"
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageghana} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Yaw Owusu – Studying in USA</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Super helpful throughout the process. I liked how they shared personal tips for settling abroad too."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageuae} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Rania Yusuf – Studying in Australia</p>
      </div>
    </div>
  </div>
</div>

<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Flyurdream explained the whole process patiently, and their documentation support was on point."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imagepakistan} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Ahmed Raza – Studying in Germany</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "Everything was well-organized – admissions, visa, even accommodation options!"
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageuae} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Ahmed Nabil – Studying in Ireland</p>
      </div>
    </div>
  </div>
</div>


<div class="carousel-item">
  <div class="row ">
    <div class="col-md-6 ">
      <div class="p-4 bg-white" style={{ border: "2px solid #2c82c4", borderRadius: "5px", boxShadow: "5px 10px rgb(21, 94, 150)", height: "125px" }}>
        "The counsellors were friendly and very informative. They even helped me find part-time job options once I arrived in Melbourne."
      </div>
      <div class="text-end  " style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
        <img src={imageindia} alt="" style={{ width: "15px" }} />
        <p class="pt-3 " >Aditya Mehta – Studying in Germany</p>
      </div>
    </div>
  </div>
</div>
</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wcu pt-5 pb-5">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <h6>Why Choose Us</h6>
                <h1 className="pt-3 pb-3 display-5">
                  Offer Tailor Made Services That Our Client Requires
                </h1>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="DirectInterviews-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#DirectInterviews"
                      type="button"
                      role="tab"
                      aria-controls="DirectInterviews"
                      aria-selected="true"
                    >
                      <i className="ri-team-line"></i>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="FasterProcessing-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#FasterProcessing"
                      type="button"
                      role="tab"
                      aria-controls="FasterProcessing"
                      aria-selected="false"
                    >
                      <i className="ri-id-card-line"></i>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="VisaAssistance-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#VisaAssistance"
                      type="button"
                      role="tab"
                      aria-controls="VisaAssistance"
                      aria-selected="false"
                    >
                      <i className="ri-flight-takeoff-line"></i>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="CostEffective-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#CostEffective"
                      type="button"
                      role="tab"
                      aria-controls="CostEffective"
                      aria-selected="false"
                    >
                      <i className="ri-money-rupee-circle-line"></i>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="DirectInterviews"
                    role="tabpanel"
                    aria-labelledby="DirectInterviews-tab"
                  >
                    <div>
                      <h2 className="display-6">Direct Interviews</h2>
                      <img
                        src={imageInterview}
                        alt="Direct Interviews"
                        className="img-fluid"
                      />
                      <p className="pt-3">
                        Our team comprises experienced professionals who are
                        passionate about education and dedicated to student
                        success. With backgrounds in international education,
                        counseling, and admissions, our advisors are equipped to
                        provide insightful guidance tailored to each student’s
                        unique needs.
                      </p>
                      <a href="#" className="Explore">
                        <i className="ri-arrow-right-double-line"></i> Explore
                      </a>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="FasterProcessing"
                    role="tabpanel"
                    aria-labelledby="FasterProcessing-tab"
                  >
                    <div>
                      <h2 className="display-6">Faster Processing</h2>
                      <img
                        src={imageProcesser}
                        alt="Faster Processing"
                        className="img-fluid"
                      />
                      <p className="pt-3">
                        Our team comprises experienced professionals who are
                        passionate about education and dedicated to student
                        success. With backgrounds in international education,
                        counseling, and admissions, our advisors are equipped to
                        provide insightful guidance tailored to each student’s
                        unique needs.
                      </p>
                      <a href="#" className="Explore">
                        <i className="ri-arrow-right-double-line"></i> Explore
                      </a>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="VisaAssistance"
                    role="tabpanel"
                    aria-labelledby="VisaAssistance-tab"
                  >
                    <div>
                      <h2 className="display-6">Visa Assistance</h2>
                      <img
                       src={imageVisa}
                        alt="Visa Assistance"
                        className="img-fluid"
                      />
                      <p className="pt-3">
                        Our team comprises experienced professionals who are
                        passionate about education and dedicated to student
                        success. With backgrounds in international education,
                        counseling, and admissions, our advisors are equipped to
                        provide insightful guidance tailored to each student’s
                        unique needs.
                      </p>
                      <a href="#" className="Explore">
                        <i className="ri-arrow-right-double-line"></i> Explore
                      </a>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="CostEffective"
                    role="tabpanel"
                    aria-labelledby="CostEffective-tab"
                  >
                    <div>
                      <h2 className="display-6">Cost-Effective</h2>
                      <img
                        src={imageCost}
                        alt="Cost-Effective
                    "
                        className="img-fluid"
                      />
                      <p className="pt-3">
                        Our team comprises experienced professionals who are
                        passionate about education and dedicated to student
                        success. With backgrounds in international education,
                        counseling, and admissions, our advisors are equipped to
                        provide insightful guidance tailored to each student’s
                        unique needs.
                      </p>
                      <a href="#" className="Explore">
                        <i className="ri-arrow-right-double-line"></i> Explore
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
