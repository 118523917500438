import React from 'react';
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imageCompany from "../../assets/global-reach-local-impact_banner.png";
const GlobalReachLocalImpact = () => {
    return (
        <div >
        <div className="inner-banner-section">
        <div className="inner-banner-mask"></div>
        <img src={imageCompany} alt="Company" className="img-fluid" />
        <div className="inner-banner-caption">
          <h2 className="display-5">Global Reach, Local Impact</h2>
          <p>Your Gateway to the World’s Top Recruiters
          </p>
        </div>
      </div>
      <div className="osc-section pt-5 pb-5">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              
              <p className="text-center">
              we connect you to a global network of vetted and trained recruitment agents, so you can focus on growing your institution without the hassle. With over 8,000+ skilled recruiters, we enable you to reach new markets and scale your recruitment efforts effortlessly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='common-section pt-5'>
        <div className="container-fluid">
            <div className="container">
            <h4 className="display-6">A Global Network You Can Trust</h4>
                <ul className="arrow-v-list-1">
                    <li><b>Vetted Professionals:</b> Every recruiter undergoes thorough vetting, training, and continuous coaching to meet the highest standards.</li>
                    <li><b>Unmatched Scale:</b> Expand your reach across diverse markets while managing everything through one reliable point of contact.</li>
                    <li><b>Dynamic Tools:</b> Empower recruiters with the latest technology and processes to deliver outstanding results.</li>
                    
                </ul>
                <h4 className="display-6 pt-3">Smarter Tools for Recruiters, Better Outcomes for You</h4>
                    <h5>Document Manager</h5>
                    <p>Streamline communication by giving recruiters access to up-to-date forms and documents, reducing repetitive inquiries and saving time.</p>
                    <h5>Dynamic Application Forms</h5> 
                    <p>Stay accurate and efficient with automatically generated forms that include up-to-date entry requirements and application details tailored to your institution.</p>
                    <h5>Results-Driven Processes</h5>
                    <p>Structured workflows help recruiters minimize common application errors, ensuring a smoother process and better outcomes.</p>
                <h4 className="display-6 pt-3">Supporting Excellence Through Innovation</h4>
                <h5>One Contact, Endless Opportunities</h5>
                <p>With Flyurdream, you gain access to an extensive recruiter network without the complexity of managing multiple contacts.</p>
                <h5>Quality at Scale</h5>
                <p>Our recruiters are nurtured to understand your institution’s unique needs and deliver consistent, high-quality results.</p>
            </div>
        </div>
    </div>
    </div>
    );
  };
  
  export default GlobalReachLocalImpact;