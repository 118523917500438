import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../Footer/footer";
import "remixicon/fonts/remixicon.css";
import "bootstrap/dist/css/bootstrap.min.css";
import imageCompany from "../../assets/company.png";

const Payment = () => {
  return (
    <div>
      {/* Navbar */}
        {/* Main Content */}
      <div className="inner-banner-section">
    <div className="inner-banner-mask"></div>
    <img src={imageCompany} alt="Payments" className="img-fluid"/>
    <div className="inner-banner-caption">
      <h2 className="display-5">Payment Terms 
      </h2>
    </div>
  </div>
  
      <div className="osc-section pt-5 pb-5">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 className="display-5"> Payment   <span className="text-c-2">Terms</span></h2>
              </div>
              <p className="text-center">
                At FlyUrDream, we are committed to providing transparent and fair payment and refund policies to ensure a seamless experience for our clients. Please review the following guidelines carefully.</p>
            </div>
          </div>
         
        </div>
      </div>
    </div>
    <div className="common-section pt-5">
      <div className="container-fluid">
        <div className="container">
          
          <div className="row">
           <h4 className="display-6">Payment Policy

           </h4>
            <ul className="arrow-v-list-1">
              <li><b>Service Fees:</b> All fees for services provided by FlyUrDream are clearly outlined in our service agreements. Payments are due upon receipt of the invoice and must be completed before the commencement of services.</li>
              <li><b>Payment Methods:</b> We accept various payment methods, including bank transfers, credit/debit cards, and online payment platforms. Detailed instructions for each payment method will be provided upon invoicing.</li>
              <li><b>Currency:</b> All payments are to be made in the currency specified in the invoice. Any currency conversion fees or bank charges are the responsibility of the client.</li>
              
            </ul>
            <h4 className="display-6">Refund Policy

            </h4>
            <p>FlyUrDream offers refunds under specific circumstances as outlined below:</p>
            <ul className="arrow-v-list-1">
              <li><b>Service Non-Eligibility:</b> If a client is deemed ineligible for a particular service or program after payment has been made, a refund will be issued, deducting any administrative fees incurred during the process.</li>
              <li><b>Program Closure:</b> In the event that a program or service is closed or unavailable after payment, clients are entitled to a full refund of the amount paid.</li>
             
            </ul>
            <h4 className="display-6">Non-Refundable Fees

            </h4>
            <p>Certain fees are non-refundable, including but not limited to:</p>
            <ul className="arrow-v-list-1">
              <li><b>Application Fees:</b> Fees paid to educational institutions or other third parties on behalf of the client are non-refundable, as these are subject to the policies of the respective institutions.</li>
              <li><b>Processing Fees:</b> Administrative fees incurred during the processing of applications or services are non-refundable once the process has commenced.</li>
              
            </ul>
            <h4 className="display-6">Refund Process

            </h4>
            <p>Certain fees are non-refundable, including but not limited to:</p>
            <ul className="arrow-v-list-1">
             <li><b>Request Submission:</b> Clients must submit a formal refund request in writing, providing all necessary documentation to support the claim.</li>
             <li><b>Processing Time:</b> Refunds will be processed within 10 working days from the date of approval of the refund request.</li>
             <li><b>Refund Method:</b> Refunds will be issued using the same method and to the same account or card used for the original payment, unless otherwise agreed upon in writing.</li>
            
              
            </ul>
          </div>
        </div>
      </div>
    </div>

      {/* Footer */}
    </div>
  );
};

export default Payment;
