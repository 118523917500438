import React from 'react';
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imageCompany from "../../assets/student-crm_banner.png";

const StudentCRM = () => {
  return (
    <div >
    <div className="inner-banner-section">
    <div className="inner-banner-mask"></div>
    <img src={imageCompany} alt="Company" className="img-fluid" />
    <div className="inner-banner-caption">
      <h2 className="display-5">Student CRM</h2>
      
    </div>
  </div>
 
<div className='common-section pt-5'>
    <div className="container-fluid">
        <div className="container">
        <h4 className="display-6">Single View of Your Student</h4>
        <p>Connect every stage of the student application process into a unified platform. Instantly access vital data to make informed decisions and provide a seamless experience for your students.</p>
        <h4 className="display-6">Faster Lead Management</h4>
        <p>Bring together leads from multiple sources—spreadsheets, Facebook, Instagram, and more—into one centralized system. Start actioning your leads faster and smarter.</p>
        <h4 className="display-6">Better Teamwork, Smarter Workspaces</h4>

            <ul className="arrow-v-list-1">
                <li><b>Set Status for Each Student:</b> Track where each student is in the application process with clear, customizable statuses.</li>
                <li><b>Pause Students:</b> Eliminate confusion by pausing inactive students for a cleaner workflow.</li>
                <li><b>Counsellor Workspaces:</b> Assign dedicated workspaces for each counsellor, ensuring clarity and focus.</li>
                <li><b>Managerial Insights:</b> Get a full-picture view of your team’s progress, making it easier to monitor performance and optimize workflows.</li>
                
            </ul>
            <h4 className="display-6 pt-3">Secure and Future-Proof</h4>
               
            <ul className="arrow-v-list-1">
                <li><b>Seamless Transition Management:</b> When team members change, your records stay intact. Keep your data organized and accessible, no matter the staffing changes.</li>
                <li><b>Data Security:</b> Protecting your student information is our top priority. Flyurdream CRM ensures your data remains secure and compliant.</li>
               
                
            </ul>
        </div>
    </div>
</div>
</div>
  );
};

export default StudentCRM