import React from 'react';
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imageCompany from "../../assets/institution-list_banner.png";

const InstitutionList = () => {
  return (
    <div >
    <div className="inner-banner-section">
    <div className="inner-banner-mask"></div>
    <img src={imageCompany} alt="Company" className="img-fluid" />
    <div className="inner-banner-caption">
      <h2 className="display-5">Institution List</h2>
      <p>---
      </p>
    </div>
  </div>
  <div className="osc-section pt-5 pb-5">
  <div className="container-fluid">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          
          <p className="text-center">
         --
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
  );
};

export default InstitutionList