import React from "react";
import footerOneImage from "../../assets/FUD-logo1-1-1-3.png"
import 'remixicon/fonts/remixicon.css';
import '../../assets/js/iasBadge.js';
import footerIasbadget from "../../assets/ias_badget.png"
const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year dynamically


  return (
    <div className="header">
      <div className="need-help">
        <div className="container-fluid">
          <div className="container pt-3 pb-3">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="kmd-text">Know more details</div>
              </div>
              <div className="col-md-6 col-sm-12">
                <ul className="kmd-list">
                  <li>
                    <a href="/recruitment-partner">Recruiters</a>
                  </li>
                  <li>
                    <a href="/institutions-page">Institutions</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Team</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer footer-bg-1">
        <div className="container-fluid">
          <div className="container pt-3">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <h3 className="display-6">Reach Out to Us</h3>
                <div className="row">
                  {/* <div className="col-md-12">
                  <a className="fot-call-phone" href="tel:+919666669160">+91 9666669160</a>
                </div> */}
                  <div className="col-md-12">
                    <ul className="fot-list-media">
                      <li>
                        <a href="https://www.facebook.com/flyurdream/?locale=en_GB">
                          <i className="ri-facebook-fill"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.twitter.com/flyurdream/">
                          <i className="ri-twitter-fill"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/fly.urdream/?hl=en-gb">
                          <i className="ri-instagram-fill"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/flyurdream/">
                          <i className="ri-linkedin-fill"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/">
                          <i className="ri-youtube-fill"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-12 ">
                    <div className="ias-head">
                    <a>
                      <img
                        src={footerOneImage}
                        alt="logo"
                        height="150px"
                      />
                    </a>
                    <a href="https://www.icef.com/agency/0016M00002d5jGAQAY">
                      <img
                        src={footerIasbadget}
                        alt="logo"
                        height="100px"
                      />
                    </a>
                    {/* <div>
                      <span id='iasBadge' data-account-id='6401'>Hii</span>
                    </div> */}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <address>
                      <span className='head-off'>Head Office,</span> Vista Business Centre, 50 Salisbury Road, Hounslow,
                      England, TW4 6JQ
                    </address>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="col-md-8 col-sm-12">
                
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <h3 className="display-6">Recruiters</h3>
                    <ul className="fot-list-v-one">

                      <li>
                        <a href="/course-search">Course search</a>
                      </li>
                      <li>
                        <a href="/student-crm">Student CRM</a>
                      </li>
                      <li>
                        <a href="/streamlined-application-solutions">Streamlined Application Solutions</a>
                      </li>
                      <li>
                        <a href="/partners-support-training">Partners Support & training</a>
                      </li>
                      <li>
                        <a href="/institution-list">Institution List</a>
                      </li>

                    </ul>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <h3 className="display-6">Institutions</h3>
                    <ul className="fot-list-v-one">
                      <li>
                        <a href="/institutions-page">
                          Partnering for Success
                        </a>
                      </li>
                      <li>
                        <a href="/global-reach-local-impact">
                          Global Reach, Local Impact
                        </a>
                      </li>
                      <li>
                        <a href="/excellence-through-quality">Excellence Through Quality</a>
                      </li>
                      <li>
                        <a href="/storiesof-transformation">
                          Stories of Transformation
                        </a>
                      </li>

                    </ul>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <h3 className="display-6">Company</h3>
                    <ul className="fot-list-v-one">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      {/* <li>
                        <a>About Us</a>
                      </li> */}
                      <li>
                        <a href="/faqs">Our Faqs</a>
                      </li>
                      {/* <li>
                        <a >Blogs</a>
                      </li> */}
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
               
                {/* <div className="row">
                  <div className="col-md-12 mb-3 ">
                    <ul className="fot-list-h-one">
                      <li>
                        <a href="#">United States</a>
                      </li>
                      <li>
                        <a href="#">Canada</a>
                      </li>
                      <li>
                        <a href="#">United Kingdom</a>
                      </li>
                      <li>
                        <a href="#">Germany</a>
                      </li>
                      <li>
                        <a href="#">Netherlands</a>
                      </li>
                      <li>
                        <a href="#">Israel</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid footer-bg-2">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 ">
                <span className="pt-c-10 d-block copy-r-text">
                  © {currentYear} Flyurdream. All Rights Reserved.
                </span>
              </div>
              <div className="col-md-6 col-sm-12">
                <ul className="fot-list-h-two">
                  <li>
                    <a href="/privacy-policy">Privacy-Policy </a>
                  </li>
                  <li>
                    <a href="/cookie-policy">Cookie-Policy </a>
                  </li>
                  <li>
                    <a href="/payment-terms">Payment Terms</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
