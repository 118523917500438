import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import { Link } from 'react-router-dom';
import imagePartner1 from "../../assets/Recruitment_Partners_At_FlyUrDream.jpg";
import imagePartner2 from "../../assets/recruitment_partners_1.png";
import imagePartner3 from "../../assets/recruitment_partners_banner.png";
import axios from 'axios';
import 'remixicon/fonts/remixicon.css';
import { toast, ToastContainer } from 'react-toastify';

const RecruitmentPartner = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });
  const [ipAddress, setIpAddress] = useState("");
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json"); 
        setIpAddress(response.data.ip); 
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIpAddress("Unable to fetch IP"); // Fallback in case of error
      }
    };

    fetchIpAddress();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      id: 0,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      country: formData.country,
      message: formData.message,
      type: "Recruiter",
      ipAddress: ipAddress,
      macAddress: "string",
      website: "FlyUrDream.com",
      createdOn: new Date().toISOString(),
      isActive: true,
    };
console.log('before', payload)
    try {
      const response = await axios.post('https://fudreamapi.azurewebsites.net/AddFlyurdreamEnquiry', payload);

    toast.success(response.data.message)
    setFormData({
      name: "",
      email: "",
      phone: "",
      country: "",
      message: "",
    });
    console.log('after', response.data)
    } catch (error) {
      toast.error("Error submitting enquiry:", error);
    }
  };
  return (
    <div >
      <div className="institutions-banner-section">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="video-frame">
          <img src={imagePartner3} className="d-block w-100" alt="..." />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="video-content text-c-1">
            <h4 className="display-6">Recruitment Partners</h4>
            <p>At FlyUrDream, we recognize the pivotal role that recruitment partners play in guiding students toward fulfilling their international education aspirations. Our Recruitment Partner Program is designed to empower educational consultants and agencies with the tools, resources, and support necessary to excel in the dynamic field of student recruitment.</p>
            <div className="row">
                <div className="col text-center count-box">
                  <h4>500+</h4>
                  <p>Partners </p>
                </div>
                <div className="col text-center count-box">
                  <h4>8+</h4>
                  <p>Locations </p>
                </div>
                <div className="col text-center count-box">
                  <h4>10k</h4>
                  <p>Success Students </p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="in-banner-layer">
      
    </div>
    
  </div>
    <div className="osc-section pt-5 pb-5">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="img-box-1">
                <img src={imagePartner1} className="img-fluid" alt=""/>
                {/* <div className="img-box-1-content">
                  <h5>Why Partner with FlyUrDream? </h5>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      Extensive Institutional Network
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">Gain access to a diverse portfolio of over 1,000 educational institutions across Canada, the United States, the United Kingdom, Australia, and Ireland. This extensive network enables you to offer students a wide array of programs and destinations, aligning with their academic goals and preferences.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      Competitive Commission Structure
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">We value the dedication and hard work of our partners. Our transparent and competitive commission model ensures timely and reliable payouts at multiple stages of the student application and enrollment process, recognizing your contributions to student success.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      Advanced Recruitment Platform
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      Leverage our user-friendly, AI-powered platform to streamline the recruitment process. From program discovery to application submission and tracking, our platform simplifies operations, allowing you to focus on providing personalized guidance to students.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                      Comprehensive Training and Support
                    </button>
                  </h2>
                  <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      Stay ahead in the ever-evolving education sector with our industry-leading insights and training programs. We offer regular webinars, workshops, and access to a wealth of resources to enhance your knowledge and skills, ensuring you provide the best advice to your students.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                      Dedicated Account Management
                    </button>
                  </h2>
                  <div id="flush-collapseFive" className="accordion-collapse collapse " aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      Our team of experienced account managers is committed to supporting you at every step. Whether you need assistance with applications, visa processes, or student counseling, we are here to provide prompt and effective solutions.
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div className="common-section  bg-bl-wr  text-c-1">
      {/* <div className="half-img-recruit-right">
        <img src={imagePartner2} className="img-fluid"/>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 pt-5 pb-5">
            <h4 className="display-6">Partner Benefits:</h4>
              <ul className="arrow-v-list-1">
                <li><b>Global Reach:</b> Expand your services to students worldwide, offering opportunities in top study destinations.</li>
                <li><b>Marketing Support:</b> Access co-branded marketing materials and participate in joint promotional activities to enhance your outreach.</li>
                <li><b>Quality Assurance:</b> Benefit from our rigorous vetting processes and quality checks, ensuring that you represent reputable institutions and provide accurate information to students.</li>
              </ul>
              <h4 className="display-6">Join Us in Shaping Futures</h4>
              <p>By partnering with FlyUrDream, you become part of a global network dedicated to making quality education accessible to students everywhere. Together, we can guide students toward achieving their dreams and building successful futures.</p>
              <h4 className="display-6">Get Started Today</h4>
              <p>Embark on a rewarding partnership with FlyUrDream. Contact us to learn more about our Recruitment Partner Program and how we can collaborate to make a positive impact in the world of international education.</p>
          </div>
          <div className="col-md-6 col-sm-12 pt-100 text-center remove-pt-100-767">
          <img src={imagePartner2} className="img-fluid"/>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-wl-br pt-5 c-form-1">
        <div className="d-flex align-items-center bd-highlight mb-3 container" >

          <div className="p-2 bd-highlight col-md-6 col-sm-12">
          Let’s Connect
              <h4 className="display-6">Send Your Message
              </h4>
              <p>Please feel free to get in touch with us using the contact form below.

                </p>
  
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <label className="form-label">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Country</label>
                      <select
                        className="form-select"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Choose Country
                        </option>
                        <option value="USA">USA</option>
                        <option value="CANADA">Canada</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Message</label>
                      <textarea
                        className="form-control"
                        name="message"
                        placeholder="Message"
                        style={{ height: "100px" }}
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
          </div>
          <div className="p-2 bd-highlight col-md-6 col-sm-12 text-c-3 c-form-1-content "><p className="display-6 pl-30"> We are excited to collaborate with recruitment partners worldwide to create life-changing opportunities for students. As a trusted UK consultancy, we specialize in helping students achieve their academic dreams by providing expert guidance and access to top universities and colleges across the UK.</p>
        </div>
        </div>
        
    </div>
    {/* <div className="common-section pt-5">
      <div className="container-fluid">
        <div className="container">
         
          <div className="row">
            <div className="col-md-6 col-sm-12">
           
             
              We are excited to collaborate with recruitment partners worldwide to create life-changing opportunities for students. As a trusted UK consultancy, we specialize in helping students achieve their academic dreams by providing expert guidance and access to top universities and colleges across the UK.
            </div>
            <div className="col-md-6 col-sm-12">
              Let’s Connect
              <h4 className="display-6">Send Your Message
              </h4>
              <p>Please feel free to get in touch with us using the contact form below.

                </p>
  
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <label className="form-label">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Country</label>
                      <select
                        className="form-select"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Choose Country
                        </option>
                        <option value="USA">USA</option>
                        <option value="CANADA">Canada</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label className="form-label">Message</label>
                      <textarea
                        className="form-control"
                        name="message"
                        placeholder="Message"
                        style={{ height: "100px" }}
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
            </div>
          </div>
            
          </div>
        </div>
          <ToastContainer />
      </div> */}
    </div>
  );
};

export default RecruitmentPartner;
