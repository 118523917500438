import React from 'react';
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imageCompany from "../../assets/streamlined-application-solutions_banner.png";

const StreamlinedApplicationSolutions = () => {
  return (
    <div >
    <div className="inner-banner-section">
    <div className="inner-banner-mask"></div>
    <img src={imageCompany} alt="Company" className="img-fluid" />
    <div className="inner-banner-caption">
      <h2 className="display-5">Streamlined Application Solutions</h2>
      <p>Process Applications Faster Than Ever
      </p>
    </div>
  </div>
  <div className="osc-section pt-5 pb-5">
  <div className="container-fluid">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
        <div className="section-title">
                  <h2 className="display-5">
                    Process Applications Faster Than Ever


                  </h2>
                </div>
          <p className="text-center">
          Empower your counsellors to deliver a seamless application experience, ensuring happier students and greater efficiency.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div className='common-section pt-5'>
    <div className="container-fluid">
        <div className="container">
        <h4 className="display-6">Streamlined Submission</h4>
        <p>Easily manage all aspects of the application process in one place:</p>
            <ul className="arrow-v-list-1">
                <li>Handle multiple course options effortlessly.</li>
                <li>Submit applications faster with a simplified workflow.</li>
                <li>Take care of fees without the hassle.</li>
                
            </ul>
            <h4 className="display-6 pt-3">Real-Time Updates</h4>
                <p>Stay informed at every step with in-platform messaging:</p>
                <ul className="arrow-v-list-1">
	<li>Get instant notifications from our Global Admissions Team on application progress.</li>
	<li>Quickly resolve queries and ensure timely submissions.</li>
	
</ul>
<h4 className="display-6 pt-3">Document Manager</h4>
<p>Stay organized and stress-free with our smart document management system:</p>
<ul className="arrow-v-list-1">
	<li>Know exactly what documents are required for each application.</li>
	<li>Upload and manage files in just seconds.</li>
	<li>Keep everything in one secure, easy-to-access location.</li>
	
</ul>
                <h5>Benefits You’ll Love</h5>
                <ul className="arrow-v-list-1">
	<li><b>Faster Turnaround Times:</b> Process applications quicker, keeping students engaged and satisfied.</li>
	<li><b>Enhanced Team Productivity:</b> Equip counsellors with tools to handle more applications efficiently.</li>
	<li><b>Error-Free Documentation:</b> Avoid delays with clear document requirements and instant uploads.</li>
</ul>
        </div>
    </div>
</div>
</div>
  );
};

export default StreamlinedApplicationSolutions