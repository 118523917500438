import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Footer/footer";
import imagePartner1 from "../../assets/Institutions_At_FlyUrDream.jpg";
import imagePartner3 from "../../assets/Institutions_banner.png";
import 'remixicon/fonts/remixicon.css';
import imageCard from "../../assets/institutions-middle-card.jpeg";

const InstitutionPage = () => {
  return (
    <div >
     <div className="institutions-banner-section">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="video-frame">
          <img src={imagePartner3} className="d-block w-100" alt="..." />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="video-content text-c-1">
            <h4 className="display-6">Institutions</h4>
            <p>At FlyUrDream, we are dedicated to fostering meaningful partnerships with educational institutions worldwide. Our mission is to connect universities and colleges with a diverse pool of qualified international students, facilitating enriching academic experiences and cultural exchanges.</p>
            <div className="row">
                <div className="col text-center count-box">
                  <h4>500+</h4>
                  <p>Recruitment Partners</p>
                </div>
                <div className="col text-center count-box">
                  <h4>15+</h4>
                  <p>Source Countries </p>
                </div>
                <div className="col text-center count-box">
                  <h4>10k+</h4>
                  <p>Success Students </p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="in-banner-layer">
      
    </div>
    
  </div>
    <div className="osc-section pt-5 pb-5">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="img-box-1">
                <img src={imagePartner1} className="img-fluid" alt=""/>
                {/* <div className="img-box-1-content">
                  <h5>Why Partner with FlyUrDream?</h5>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      Extensive Global Network
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">Leverage our expansive network of recruitment partners and students across multiple countries, enhancing your institution’s global reach and diversity.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      Quality Student Recruitment
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">We prioritize the recruitment of high-caliber students whose academic goals align with your institution’s offerings, ensuring a mutually beneficial relationship.</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      Comprehensive Marketing Solutions
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      Enhance your institution’s visibility through targeted marketing strategies, including virtual and in-person events, innovative online campaigns, and the promotion of specialized courses and scholarships.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                      Streamlined Application Process
                    </button>
                  </h2>
                  <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      Receive thoroughly vetted and complete applications, allowing your admissions team to focus on selecting the best candidates without the burden of preliminary screenings.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                      Dedicated Support Team
                    </button>
                  </h2>
                  <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      Collaborate with our experienced professionals who are adept at matching suitable students to your programs, managing applications efficiently, and facilitating effective communication between your institution and prospective students.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div className="common-section  bg-3  text-c-1">
      <div className="half-img-bottom-right">
        <img src={imageCard} className="img-fluid"/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 pt-5 pb-5">
            <h4 className="display-6">Benefits of Partnering with FlyUrDream</h4>
              <ul className="arrow-v-list-1">
                <li><b>Diverse Student Body:</b> Enrich your campus with students from various nationalities, fostering a vibrant and inclusive academic environment.</li>
                <li><b>Enhanced Retention Rates:</b> Our diligent academic and financial vetting processes help minimize dropouts, ensuring that students are well-prepared and committed to their studies.</li>
                <li><b>Tailored Recruitment Strategies:</b> We work closely with your institution to develop customized recruitment plans that align with your specific goals and objectives.</li>
              </ul>
              <h4 className="display-6">Join Us in Shaping the Future of Education</h4>
              <p>By partnering with FlyUrDream, your institution gains access to a wealth of resources and expertise in international student recruitment. Together, we can create transformative educational experiences that benefit students and institutions alike.</p>
              <h4 className="display-6">Contact Us Today</h4>
              <p>To learn more about how FlyUrDream can support your institution’s international recruitment efforts, please reach out to our partnership team. We look forward to collaborating with you to achieve your enrollment goals and enrich your academic community.</p>
          </div>
          
        </div>
      </div>
    </div>
    </div>
  );
};

export default InstitutionPage;
